$font-default: 'Avenir Next';
$font-primary: 'Lulo Clean';


$text-black: #000;
$text-white: #fff;
$text-gray: rgb(128,128,128);
$text-gray-2: rgba(0, 0, 0, 0.3);
$text-gray-3: rgba(0, 0, 0, 0.6);

$purple: #480d94;
$purple-dark: #2e0e63;
$purple-lite: #f6f1fe;
$orange: #ff7547;
$yellow: #febc00;
$black: #000000;
$gray-1: #8e8e8e;
$gray-2: #c7c7c7;
$gray-3: #e5e5e5;
$gray-4: #f2f2f2;
$gray-5: #f7f7f7;
$white: #ffffff;
$white-1: rgb(247, 247, 247);
$orange: rgb(255,117,71);
$black: #000;

$bg-black: #000;
$bg-white: #fff;
$bg-gray: rgb(128,128,128);
$bg-gray-2: #f2f2f2;

$container-default: 108rem;
$container-primary: 144rem;


$table-screen: 1023px;
$mobile-screen: 767px;