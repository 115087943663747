@import "../../assets/styles/vars";

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7.3rem;
}

.title {
  width: 42.2rem;
  font-family: Lulo Clean;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.6rem;
  text-align: center;
  margin: 3.6rem auto;
}

.productListWrapper {
  width: 64.3rem;
}

.empty {
  text-align: center;
  padding: 2rem 0 6rem;
}

.message {
  margin: 0;
  font-size: 1.7rem;
  line-height: 1.35;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: $table-screen) {
  .wrapper {
    display: block;
    margin-bottom: 3.6rem;
  }
  .productListWrapper {
    width: 100%;
  }
}

@media (max-width: $mobile-screen) {
  .title {
    width: auto;
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
}