@import "../../assets/styles/vars";

.list {
    padding: 0;
    margin: 0 -5px;
    list-style: none;
    display: flex;
}

.item {
    padding-left: 5px;
    padding-right: 5px 
}

.icon {
    width: 24px;
    height: 24px;
    display: block;
}

.link {
    transition: opacity .3s;

    &:hover {
        opacity: .5;
    }
}
