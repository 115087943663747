@import "../../assets/styles/vars";

.wrapper {
  height: 57.4rem;
  background-color: $purple-lite;
}

.banner {
  height: 57.4rem;
  h2 {
    font-family: Lulo Clean;
    font-size: 2.4rem;
    padding: 9rem 0 4rem 0;
    margin: 0;
  }
}

.blocks {
  display: flex;
  flex-direction: column;
  height: 41rem;
  flex-wrap: wrap;
  & > div {
    width: 47rem;
    display: flex;
    align-items: flex-start;
    margin-bottom: 3.8rem;
    & > img {
      width: 7.8rem;
      height: 7.6rem;
    }
    & > div {
      margin-left: 1rem;
      margin-top: .9rem;
      font-size: 1.7rem;
      line-height: 2rem;
      font-weight: 400; 
      a {
        color: $purple;
        text-decoration: underline;
      }
    }
  }
}

.title {
  font-family: Avenir Next;
  font-size: 1.7rem;
  line-height: 2rem;
  font-weight: 600;
}

@media (max-width: $table-screen) {
  .wrapper {
    height: auto;
    background-color: $purple-lite;
  }

  .blocks {
    display: flex;
    flex-direction: column;
    height: auto;

    & > div {
      width: auto;
    }
  }

  .banner {
    height: auto;

    h2 {
      padding: 5rem 0 2rem;
      margin: 0;
      font-size: 2rem;
    }
  }

}