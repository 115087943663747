@import "../../../../assets/styles/vars";

.wrapper {
 
}

.paymentContainer {
  padding-bottom: 3.6rem;
  border-bottom: 1px solid $gray-2;
  margin-bottom: 2.8rem;
}

.title {
  display: block;
  margin: 0 0 1rem;
  color: $black;
  font-size: 1.7rem;
  line-height: 1.4;
  font-weight: 600;
  font-family: $font-default;
}

.titleWrapper {
  margin-bottom: 2.8rem;
}

.billingAddress {
  border-bottom: 1px solid $gray-2;
  padding-bottom: 3.6rem;
  border-bottom: 1px solid $gray-2; 
}

.promoCode {
  margin-top: 2.8rem;
}
