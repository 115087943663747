@import "../../assets/styles/vars";

.variations {

    .module {
        &:first-child {
            border-top: .1rem solid $gray-2;
        }
    }
}

.module {
    padding-top: 3rem;
    padding-bottom: 3rem;

    + .module {
        border-top: .1rem solid $gray-2;
    }
}

.title {
    display: block;
    font-size: 1.7rem;
    line-height: 1.35;
    font-weight: 600;
    font-family: $font-default;
}

.grid {
    display: flex;
    margin: 0 -.5rem;
    flex-wrap: wrap;
}

.cell {
    width: 50%;
    padding: 0 .5rem;
    margin-top: 1rem;
}

.btn {
    display: flex;
    border-radius: 1rem;
    cursor: pointer;
    justify-content: space-between;
    height: 100%;

    padding: 2rem 1.5rem;
    min-height: 6rem;
    position: relative;

    &:hover {
        .panel {
            &:before {
                border-width: .2rem;
                border-color: $purple;
            }
        }
    }

}

.price {
    font-size: 1.7rem;
    line-height: 1.35;
    color: $black;
    padding-left: 1rem;
}

.name {
    font-family: $font-default;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 1.35;
    display: block;
}

.panel {
    &:before {
        content:"";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 1rem;
        border: .1rem solid $gray-2;
        transition: border-color .3s;
    }
}

.control {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked {
        + .panel {
            &:before {
                border-width: .2rem;
                border-color: $purple;
            }
        }
    }
}

.footer {
    padding-top: 3.5rem;
    border-top: .1rem solid $gray-2;
}

.warning {
    margin: 40px 0;
    font-size: 20px;
    color: $orange;
}

@media (max-width: $table-screen) {
    .cell {
        width: 100%;
    }
}