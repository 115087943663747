@import "../../assets/styles/vars";


.wrapper {
    width: 100%;
    height: 4.4rem;
    position: relative;
}



.magnifier {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    svg {
        width: 2rem;
        height: 2rem;
        display: block;
        fill: rgba($black, .3);
    }
}

.close {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: opacity .3s;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $bg-black;
        display: block;
    }

    &:hover {
        opacity: .5;
    }
}

.control {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 1.7rem;
    line-height: 1;
    padding: .5rem 5rem;
    background-color: $white;
    border-radius: .4rem;
    border: 1px solid $gray-2;
    

    &:-webkit-input-placeholder {
        color: rgba($black, .3);
        transition: opacity .3s;
        opacity: 1;
    }

    &:-moz-placeholder {
        color: rgba($black, .3);
        transition: opacity .3s;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: rgba($black, .3);
        transition: opacity .3s;
        opacity: 1;
    }

    &:-moz-placeholder {
        color: rgba($black, .3);
        transition: opacity .3s;
        opacity: 1;
    }

    &:focus {

        &:-webkit-input-placeholder {
            opacity: 0;
        }
    
        &:-moz-placeholder {
            opacity: 0;
        }
    
        &:-ms-input-placeholder {
            opacity: 0;
        }
    
        &:-moz-placeholder {
            opacity: 0;
        }
    }
}



.search {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
    margin: 0;
    padding: 0;
    margin:0;
    cursor: pointer;
    border: none;
    background-color: transparent;

    svg {
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        fill: $black;
    }
}
