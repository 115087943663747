@import "../../assets/styles/vars";


.card {
	width: 100%;
	border-radius: 1rem;
	background-color: $gray-5;
	padding: 0 1.5rem;
}

.cell {
	padding: 2rem 0 2rem 2.5rem;

	+ .cell {
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}
}


.header {
	font-size: 1.7rem;
	line-height: 1.35;
	

	+ .description {
		margin-top: 1rem;
	}
}

.description {
	font-size: 1.7rem;
	line-height: 1.35;

	p {
		margin: 0;

		+ p,
		+ ul,
		+ ol {
			margin-top: 1rem;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;


		+ p {
			margin-top: 1rem;
		}

		li {
			position: relative;
			padding-left: 2.5rem;

			&:before {
				content:"";
				width: 6px;
				height: 6px;
				background-color: $black;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 50%;
				margin: .8rem 0 0 .6rem;
			}
		}
	}
}

.default,
.primary {
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 2.4rem;
		height: 2.4rem;
		position: absolute;
		top: -.2rem;
		left: -3rem;
		background-size: contain;
	}
}

.default {
	&:before {
		background: url(../../assets/images/icons/check.svg) 50% 50% no-repeat;
	}
}
.primary {
	&:before {
		background: url(../../assets/images/icons/icon-cross.svg) 50% 50% no-repeat;
		
	}
}


@media (max-width: $table-screen) {

	.header,
	.description {
		font-size: 1.5rem;
	}
	
}