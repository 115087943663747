@import "../../../../assets/styles/vars";


.deliveryOptions {
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 3rem;
  margin: 3rem 0;
}


.title {
  display: block;
  margin: 0 0 1rem;
  color: $black;
  font-size: 1.7rem;
  line-height: 1.4;
  font-weight: 600;
  font-family: $font-default;
}

@media (max-width: $table-screen) {
  .title {
    font-size: 1.5rem;
    line-height: 1.35;
  }
}