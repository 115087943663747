@import "../../../assets/styles/vars";

.title {
  h2 {
    font-family: Lulo Clean;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.5rem;
    margin: 0;
    max-width: 51rem;
  }
  p {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: .4rem 0 0 0;
  }
}

@media (max-width: $table-screen) {
  .title {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.35;

    h2 {
      font-size: 1.5rem;
      line-height: 1.35;
    }

    p {
      font-size: 1.3rem;
      line-height: 1.4;
    }
  }
}