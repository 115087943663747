@import "../../assets/styles/vars";

.wrapper {
  margin: 2rem 0 3.6rem 0;
}

.radioWrapper {
  padding: 1.8rem 1.5rem;
  outline: .1rem solid $gray-2;
  box-sizing: border-box;
  border-radius: 1rem;
  transition: outline-color .3s;

  &:hover {
    outline: .1rem solid $gray-2;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.active {
    outline: .2rem solid #480D94;
  }
}

.radioItem {
  + .radioItem {
    color: $black;
    font-family: $font-default;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 1.4;
  }
}
