@import "../../../assets/styles/vars";

.container {
  display: flex;
  justify-content: space-between;
  padding: 5rem 2rem;
}

.faqContainer {
  margin: 5rem 0;
}

.order {
  display: flex;
  width: 100%;
}

.step {
  padding-right: 3.5rem;
  width: calc(100% - 32.5rem);
}

.inner {
  padding-right: 3.5rem;
  border-right: .1rem solid $gray-2;
}


@media (max-width: $table-screen) {

  .container {
    padding: 3rem 2rem;
  }

  .step {
    width: 100%;
    max-width: 38rem;
    margin: 0 auto;
    padding-right: 0;
  }

  .inner {
    padding-right: 0;
    border: none;
  }
}