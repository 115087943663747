@import "../../assets/styles/vars";

.button {
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.3rem;
  color: $white;
  padding: 11px;
  text-align: center;
  background: $purple;
  border: none;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color .3s;
  &:disabled {
    background: $gray-2;
    color: $white;
    cursor: default;
  }
}