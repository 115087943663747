@import "../../assets/styles/vars";

.dropdown {
	padding: 0 1.6rem;
	position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5rem 2rem 0;
  overflow: hidden;
}

.menu {
  width: 100%;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}


.link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $black;
	font-size: 1.5rem;
	padding: 2rem 0;
}

.iconFaq {
	background-image: url(../../assets/images/icons/icon-faq.svg);
}

.iconAccount {
	background-image: url(../../assets/images/icons/icon-account.svg);
}

.group {
	display: flex;
	align-items: center;

	.icon {
		width: 2.8rem;
		height: 2.8rem;
		display: block;
		margin: -.5rem 1rem -.5rem 0;
		background-position: 50% 50%;
		background-size: cover;
	}
}

.list {
	padding: 0;
	margin: 0;
	list-style: none;
	width: 30rem;

	&.offset {
		padding-left: 3rem;
	}
}

.item {
	+ .item {
		border-top: .1rem solid $gray-2;
	}

	&.back {
		.link {
			font-weight: 600;
			position: relative;
		}

		.icon {
			position: absolute;
			top: 0;
			left: 0;
			width: 3rem;
			bottom: 0;
			transform: translateX(-3rem);
			display: flex;
			align-items: center;

			svg {
				transform: rotate(180deg);
			}
		}
	}
}

.close {
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;
	transition: opacity .3s;
	position: absolute;
	top: 2.5rem;
	left: 2rem;

	svg {
			width: 1.4rem;
			height: 1.4rem;
			fill: $bg-black;
			display: block;
	}

	&:hover {
			opacity: .5;
	}
}

.icon {
	svg {
		fill:$black;
		width: .9rem;
		height: 1.5rem;
		display: flex;
	}
}

.banner {
	margin: 6rem 0;
}

.bannerPreview {
	display: block;
	max-width: 100%;
	height: auto;
}