@import "../../assets/styles/vars";

.menu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.item {

    + .item {
        margin-left: 3rem;
    }
}

.link {
    color: $black;
    font-size: 1.5rem;
    line-height: 2rem;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    transition: color .3s;
    font-weight: 500;

    &:hover {
        color: rgb(128,128,128);
    }
}

@media (max-width: $table-screen) {
    .menu {
        display: none;
    }
}