@import "../../assets/styles/vars";

.productList {
  h2 {
    font-family: Lulo Clean;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    margin: 0 0 2.8rem 0;
  }
}

@media (max-width: $table-screen) {
  .productList {
    h2 {
      display: none;
    }
  }
}

@media (max-width: $mobile-screen) {
  
}