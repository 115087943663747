@import "../../assets/styles/vars";

.search {
    padding-top: 3.6rem;
}

.title {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.5;
    text-transform: uppercase;
    margin:0;
}

.control {
    margin: 3.6rem auto 0;
    max-width: 50.1rem;
}

.header {
    + .body {
        margin-top: 3.6rem;
    }
}

.notFound {
    text-align: center;
    font-size: 1.7rem;
    line-height: 1.4;
    font-weight: 400;

    strong {
        font-weight: 600;
    }

    p {
        margin: 0;
       
        + p{
            margin-top: 1rem;
        }
    }
}

@media (max-width: $mobile-screen) {
    .title {
        font-size: 1.7rem;
        line-height: 1.5;
    }
}