@import "../../assets/styles/vars";

.page404 {
    background: url('../../assets/images/backgrounds/404.png') 50% 50% no-repeat;
    background-size: cover;
    padding: 18rem 0;
    text-align: center;
    color: $black;
}

.head {
    + .body {
        margin-top: 1rem;
    }
}

.body {
    max-width: 52rem;
    width: 100%;
    margin: 0 auto;
}

.description {
    font-size: 1.7rem;
    line-height: 1.4;

    p {
        margin: 0;
    }
}

.title {
    margin: 0;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.5;
    text-transform: uppercase;
    font-family: $font-primary;
}

@media (max-width: $table-screen) {

    .page404 {
        padding: 8rem 0;
    }

    .body {
        max-width: 40rem;
    }

    .title {
        font-size: 1.7rem;
    }

    .description {
        font-size: 1.5rem;
    }
}

@media (max-width: $mobile-screen) {
    .body {
        max-width: 30rem;
    }
}