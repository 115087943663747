@import "../../assets/styles/vars";

.list {
  padding: 0;
  margin: 0 0 3rem;
  list-style: none;
}

.item {
  position: relative;
  padding-left: 3.5rem;
  font-size: 1.7rem;
  line-height: 1.35;

  + .item {
    margin-top: 1rem;
  }
}

.icon {
  position: absolute;
  top: 0;
  transform: translateY(-.2rem);
  left: 0;
  width: 2.4rem;
  height: 2.4rem;
  display: block;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
