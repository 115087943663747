@import "../../assets/styles/vars";

.banner {
    height: 40rem;
    position: relative;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    display: block;

    img {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}

.panel {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;

    width: 100%;
    max-width: 108rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    padding: 4rem 0;
}

.title {
    font-size: 3.6rem;
    font-family: $font-primary;
    color: #fff;
}

@media (max-width: $table-screen) {
    .banner {
        height: 36rem;
    }

    .title {
        font-size: 3rem;
    }
}

@media (max-width: $mobile-screen) {
    .title {
        font-size: 2.2rem;
    }

    .panel {
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100%;
        left: 0;
        top: 0;
        max-width: inherit;
        transform: translateY(0);
        padding: 4rem 2rem;
    }

    .image {
        width: 100%;
        height: 100%;
        display: block;
    
        img {
            object-fit: cover;
            object-position: 50%;
        }
    }
    
}