@import "../../assets/styles/vars";

.main {
    flex: 1 0 auto;
    padding-top: 6.5rem;
}

.panel {
    margin-top: 6.5rem;
    border-bottom: 1rem solid $gray-4;

    &.open {
        .body {
            display: block;
        }

        .price {
            display: none;
        }
    }
}

.inner {
    width: 100%;
    margin: 0 auto;
    max-width: 54rem;
    padding: 0 2rem;
}


.head {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
}

.body {
    padding: 0 0 2rem;
    display: none;
}

.label {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.35;
    color: $black;
    padding-right: 2rem;
}

.total {
    font-size: 1.5rem;
    line-height: 1.35;
    position: relative;
    padding-right: 3rem;
    cursor: pointer;
    padding-left: 2rem;

    &:before {
        content:"";
        width: 2rem;
        height: 2rem;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background: url('../../assets/images/icons/icon-arrow-down.svg') 50% 50% no-repeat;
        background-size: 1.2rem .7rem;
    }
}

@media (max-width: $table-screen) {

    .panel {
        margin-top: 5rem;
    }

    .mainCheckout {
        padding-top: 0;
    }
}