@import "../../assets/styles/vars";

.burger {
    width: 1.8rem;
    height: 1.6rem;
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    cursor: pointer;

    &:before,
    &:after {
        content:"";
        width: 1.8rem;
        height: .2rem;
        border-radius: .4rem;
        background-color: $black;
        position: absolute;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}

.line {
    width: 1.8rem;
    height: .2rem;
    border-radius: .2rem;
    background-color: #000;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-.1rem);
}

@media (max-width: $table-screen) {
    .burger {
        display: block;
    }
}