@import "../../../assets/styles/vars";

.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 2.8rem -.5rem 0;

  & > div {
    padding-left: .5rem;
    padding-right: .5rem;
  }

}

.genderTooltip {
  display: flex;
  align-items: center;

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

@media (max-width: $table-screen) {
  .flex {
    flex-wrap: wrap;
    margin-top: 2rem;

    & > div {
      width: 100%;

      + div {
        margin-top: 1rem;
      }
    }
  }
}