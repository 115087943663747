@import "../../assets/styles/vars";

.grid {
	position: relative;
}


.cell {
	width: 40rem;
	flex: 0 0 40rem;
	height: auto;

	+ .cell {
		padding-left: 1rem;
	}

	&:nth-child(2) {
		.card {
			background-image: url('../../assets/images/cards/artboard-2.jpg');
		}
	}

	&:nth-child(3) {
		.card {
			background-image: url('../../assets/images/cards/artboard-3.jpg');
		}
	}

	&:nth-child(4) {
		.card {
			background-image: url('../../assets/images/cards/artboard-4.jpg');
		}
	}

	&:nth-child(5) {
		.card {
			background-image: url('../../assets/images/cards/artboard-5.jpg');
		}
	}

	&:nth-child(6) {
		.card {
			background-image: url('../../assets/images/cards/artboard-6.jpg');
		}
	}

	&:nth-child(7) {
		.card {
			background-image: url('../../assets/images/cards/artboard-7.jpg');
		}
	}

	&:nth-child(8) {
		.card {
			background-image: url('../../assets/images/cards/artboard-8.jpg');
		}
	}

	&:nth-child(9) {
		.card {
			background-image: url('../../assets/images/cards/artboard-9.jpg');
		}
	}

	&:nth-child(10) {
		.card {
			background-image: url('../../assets/images/cards/artboard-10.jpg');
		}
	}
}

.card {
	width: 100%;
	border-radius: 1rem;
	padding: 2rem 1.5rem;
	color: $black;
	background-color: $gray-5;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-image: url('../../assets/images/cards/artboard-1.jpg');
	background-size: cover;
	height: 100%;
	position: relative;
	overflow: hidden;

	&:before {
		content:"";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}
}

.inner {
	position: relative;
	z-index: 2;
}

.title {
	font-size: 1.7rem;
	line-height: 1.35;
	display: block;
	font-weight: 600;

	+ .description {
		margin-top: 1rem;
	}
}

.description {
	font-size: 1.7rem;
	line-height: 1.35;

	p {
		margin: 0;
	}
}

@media (max-width: $table-screen) {
	.card {
		max-width: inherit;
	}

	.cell {
		width: 37.5rem;
		flex: 0 0 37.5rem;
	}

	.title,
	.description {
		font-size: 1.5rem;
	}
}

@media (max-width: $mobile-screen) {
	.cell {
		width: 26.6rem;
		flex: 0 0 26.6rem;

		+ .cell {
			padding-left: 0;
		}
	}
}