@import "../../assets/styles/vars";

.inputWrapper {
  width: 100%;

  input {
    width: 100%;
    background: $bg-white;
    border: 0.5px solid $gray-2;
    box-sizing: border-box;
    border-radius: .4rem;
    padding: 1rem 1.5rem;
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin-top: 1rem;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
    &:disabled {
      background: $bg-gray-2;
      color: rgba(0, 0, 0, 0.6);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  .label {
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.3rem;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

  }
}

.message {
  margin-top: .5rem;
  width: 100%;
  color: #ee372d;
  font-size: 1.3rem;
  line-height: 1.4;
}

@media (max-width: $table-screen) {
  .inputWrapper {
    input {
      margin-top: .5rem;
      font-size: 1.5rem;
      line-height: 1.35;
    }

    .label {
      font-size: 1.5rem;
      line-height: 1.35;
    }

    + .inputWrapper {
      margin-top: 0;
    }
  }
}