@import "../../assets/styles/vars";

.related {
    margin: 5rem 0;
}

.title {
    text-align: center;
    display: block;
    font-size: 2rem;
    line-height: 1.35;
    font-weight: 600;
}

.head {
    + .body {
        margin-top: 3rem;
    }
}

@media (max-width: $table-screen) {
    .title {
        text-align: left;
    }
}

@media (max-width: $mobile-screen) {
    .title {
        font-size: 1.7rem;
    }

    .related {
        margin: 2rem 0;
    }

    .head {
        + .body {
            margin-top: 2rem;
        }
    }
}