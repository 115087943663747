@import "../../assets/styles/vars";


.section {
  background: url('../../assets/images/backgrounds/backgrounds-primary.png') 50% 50% no-repeat;
  background-size: cover;
  padding-top: 5rem;
  padding-bottom: 5rem;

}

.container {
  width: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: $container-primary;
}

.title {
  display: block;
  text-align: center;
  color: $black;
  font-size: 2rem;
  line-height: 1.35;
  font-weight: 600;
}

.head {
  + .body {
    margin-top: 3rem;
  }
}

.grid {
  display: flex;
  justify-content: center;
  margin: 0 -2.5rem;
}

.cell {
  width: 20%;
  padding: 0 2.5rem;
  text-align: center;
}

.preview {
  width: 100%;
  max-width: 11.3rem;
  background-position: 50% 50%;
  background-size: contain;
  border-radius: 50%;
  background-repeat: no-repeat;
  margin: 0 auto;

  &:before {
    content:"";
    padding-top: 100%;
    display: block;
  }
}

.headline {
  display: block;
  color: $black;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.35;
  margin-top: 1.5rem;
}

.description {
  font-size: 1.7rem;
  line-height: 1.35;
  p {
    margin: 0;
  }
}

@media (max-width: $table-screen) {

  .head {
    + .body {
      margin-top: 2rem;
    }
  }

  .headline {
    margin-top: 0;
  }

  .title {
    text-align: left;
    margin-top: 0;
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }

  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .description {
    font-size: 1.5rem;
  }

  .card {
    display: flex;
    align-items: center;
  }

  .panel {
    text-align: left;
    padding-left: 2rem;
  }


  .preview {
    flex: 0 0 11rem;
  }

  .grid {
    margin: 0 -2rem;
  }
  
  .cell {
    width: 45%;
    padding: 0 2rem;
  }
}

@media (max-width: $mobile-screen) {

  .head {
    + .body {
      margin-top: 1rem;
    }
  }

  .grid {
    flex-wrap: wrap;
  }

  .cell {
    width: 100%;

    + .cell {
      margin-top: 2rem;
    }
  }

  .preview {
    flex: 0 0 5.6rem;
    margin: 0;
  }

  .section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}