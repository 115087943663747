.MuiTypography-body1 {
  font-family: Avenir Next !important;
  font-weight: normal !important;
  font-size: 1.7rem !important;
  line-height: 2.3rem !important;
}

.MuiRadio-root {
  color: #c7c7c7 !important;
  padding: 9px !important;
  border-radius: 50% !important;
}

.Mui-checked {
  color: #480D94 !important;
}
