.swiper-container {
  height: 100%;
}

.swiper-pagination {
  & .swiper-pagination-bullet {
    width: .6rem;
    height: .6rem;
    &.swiper-pagination-bullet-active {
      background: rgba(0, 0, 0, 0.6) !important;
    }
  }
}

