@import "../../assets/styles/vars";

.grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.cell {
	width: 100%;
	
	+ .cell {
		margin-top: 2rem;
	}
}

.card {
	border-radius: 1rem;
	padding: 2rem 1.5rem;
	color: $black;
	background: $gray-5;
}

.text {
	font-size: 1.7rem;
	line-height: 1.35;

	p {
		margin: 0;

		+ p {
			margin-top: 1rem;
		}
	}
}

@media (max-width: $table-screen) {
	.text {
		font-size: 1.5rem;
	}
}