@import "../../assets/styles/vars";

.product {
  display: flex;
  padding-bottom: 24px;
  &:not(:first-child) {
    margin: 2.3rem 0;
  }
  &:not(:last-child) {
    border-bottom: 0.5px solid #C7C7C7;
  }
  &:last-child {
    margin: 2.3rem 0 0 0;
    padding-bottom: 0;
  }
  img {
    width: 11.3rem;
    height: 11.3rem;
    border-radius: 4px;
    display: block;
    object-fit: cover;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem;
  header > div:first-child {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.3rem;
    button {
      height: 2.2rem;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.price {
  font-size: 1.7rem;
  line-height: 2.3rem;
}

.description {
  margin-top: .8rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.consultation {
  margin-top: .2rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.edit {
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration-line: underline;
  color: $purple;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.variation {
  font-family: "Avenir Next";
  font-weight: 400;
  font-size: 1.4rem;
  color: #000;
  margin-top: .5rem;
}

.footer {
  margin-top: 1rem;
}

@media (max-width: $table-screen) {
  .product {
    border-bottom: 0.5px solid #C7C7C7;
    &:last-child {
      padding-bottom: 24px;
    }
    img {
      width: 11.1rem;
      height: 11.1rem;
    }
  }

  .content {
    header > div:first-child {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .description {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .consultation {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .price {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  
}

@media (max-width: $mobile-screen) {
  .product {
    img {
      width: 8.2rem;
      height: 8.2rem;
    }
  }

  .description {
    margin-top: .2rem;
  }
}