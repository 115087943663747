@import "../../assets/styles/vars";

.container {
  height: 22rem;
  background: url('../../assets/images/banner-background.png');
  background-position: center center;
  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 108rem;
  }
}

.slide {
  width: 24.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding-top: 3.6rem;
  div {
    margin-top: .8rem;
  }
  div span {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #000000;
  };
  div p {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #000000;
    margin: .2rem 0;
  }
}

.cell {

}

@media (max-width: $table-screen) {
  .container {
    height: 26rem;
    & > div {
      flex-direction: column;
      flex-wrap: wrap;
      padding: 2rem 3.5rem 0 3.5rem;
      align-items: initial;
    } 
  };
  .slide {
    padding-top: 0;
    flex-direction: row;
    align-items: initial;
    width: 100%;
    text-align: left;
    margin: 0;
    min-height: 6rem;
    margin-bottom: 2rem;
    div {
      margin-top: 0;
      margin-left: 1rem;
      width: 33.7rem;
    }
    div span {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
    div p {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
}

@media (max-width: $mobile-screen) {
  .container {
    height: auto;
    & > div {
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .slide {
    min-height: auto;
    & > div {
      width: 250px;
    }
  }
}