@import "../../assets/styles/vars";

.panel {
    padding-top: 6.5rem;
}

.wrapper {
    border-top: .1rem solid $gray-2;
}

.magnifier {
    
    svg {
        width: 5rem;
        height: 5rem;
        display: block;
    }
}

.close {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: opacity .3s;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    svg {
        width: 2rem;
        height: 2rem;
        fill: $bg-black;
        display: block;
    }

    &:hover {
        opacity: .5;
    }
}

.control {
    width: 100%;
    border: none;
    font-size: 1.7rem;
    line-height: 1;
    padding: 2rem 0;
    text-overflow: ellipsis;

    &:-webkit-input-placeholder {
        color: rgba($black, .3);
        transition: opacity .3s;
        opacity: 1;
    }

    &:-moz-placeholder {
        color: rgba($black, .3);
        transition: opacity .3s;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: rgba($black, .3);
        transition: opacity .3s;
        opacity: 1;
    }

    &:-moz-placeholder {
        color: rgba($black, .3);
        transition: opacity .3s;
        opacity: 1;
    }

    &:focus {

        &:-webkit-input-placeholder {
            opacity: 0;
        }
    
        &:-moz-placeholder {
            opacity: 0;
        }
    
        &:-ms-input-placeholder {
            opacity: 0;
        }
    
        &:-moz-placeholder {
            opacity: 0;
        }
    }
}



.magnifier {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    svg {
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        fill: rgba($black, .3);
    }
}

.inner {
    position: relative;
    padding: 0 5rem;
}

.search {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
    margin: 0;
    padding: 0;
    margin:0;
    cursor: pointer;
    border: none;
    background-color: transparent;

    svg {
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        fill: $black;
    }
}

@media (max-width: $mobile-screen) {
    .search {
        width: 1.8rem;
        height: 1.8rem;

        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    .inner {
        margin-left: -1rem;
    }
}