@import "../../assets/styles/vars";

.info {
    width: 50%;
    max-width: 48rem;
    margin: 0 0 2rem 0;
}

.title {
    display: block;
    font-family: $font-primary;
    font-weight: 700;
    margin: 0 0 1rem 0;
}

.description {
    font-size: 1.7rem;
    font-weight: 400;

    p {
        margin: 0;
    }
}

@media (max-width: $mobile-screen) {
    .info {
        width: 100%;
        max-width: inherit;
    }
}