@import "../../assets/styles/vars";


.alert {
	margin: 2.5rem 0;
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	padding: 0.75rem 3rem 1.25rem 0.75rem;
	position: relative;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}

.close {
	position: absolute;
	top: 0;
	right: 0;
	width: 3rem;
	height: 3rem;
	cursor: pointer;
	padding: .5rem;
	font-size: 2.5rem;
	line-height: 1;
	color: rgba(#000, .6);
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
}