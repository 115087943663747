@import "../../assets/styles/vars";

.card {
    border-radius: 1.4rem;
    box-shadow: 0 .5rem 1.5rem 0 rgba(0,0,0,.15);
    background-color: $bg-white;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: box-shadow .6s;

    &:hover {
        box-shadow: 0 1rem 1.5rem 0 rgba(0,0,0,.15);;
    }
}

.media {
    border-radius: 1.4rem 1.4rem 0 0;
    overflow: hidden;
    position: relative;
    
    &:before {
        content:"";
        display: block;
        padding-top: calc(183 / 244 * 100%);
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    margin: 2rem -1rem 0;
}

.cell {
    padding-left: 1rem;
    padding-right: 1rem;
}

.preview {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    image-rendering: pixelated;
}

.panel {
    padding: 1.5rem;
    border-radius: 0 0 1.4rem 1.4rem;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;    
}


.price {
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    font-weight: 700;
}

.title {
    display: block;
    margin: 0 0 .5rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.02em;
    font-weight: 600;
}

.description {
    color: $text-gray;
    font-size: 1.5rem;

    p {
        margin: 0;
    }
}

.button {
    color: $purple;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 600;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
    transition: color .3s;

    &.disable {
        cursor: default;
        color: rgba($black, .3);
    }
}

@media (max-width: $table-screen) {
    .description {
        display: none;
    }
}