@import "../../assets/styles/vars";

.summaryWrapper {
  padding: 3.5rem 0 4rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: url('../../assets/images/orderMask.png');

  .summary {
    padding: 3.6rem 2rem 4rem 2rem;
    width: 100%;
    max-width: 55rem;
    background: $white;
    border-radius: 10px;

    .summaryTitle {
      font-family: Lulo Clean;
      font-style: normal;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2.5rem;
      text-align: center;
      color: $black;
    }

    .summaryDescription {
      width: 37.3rem;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 1.7rem;
      line-height: 2.3rem;
      text-align: center;
      margin: auto;
      color: $black;
    }

    .header {
      padding-bottom: 3.6rem;
      border-bottom: 1px solid $gray-2;
    }

    .orderNumber {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 1.7rem;
      line-height: 2.3rem;
      margin-top: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid $gray-2;
      span {
        font-weight: 600;
      }
    }

    .teleConsultation {
      margin-top: 2rem;

      + .teleConsultation {
        border-top: 1px solid $gray-2;
      }
      .title {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 600;
        font-size: 1.7rem;
        line-height: 2.3rem;
        color: $black;
      }
      & > div {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 1.7rem;
        line-height: 2.3rem;
        color: $black;
        margin: 1rem 0;
      }

      button {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 1.7rem;
        line-height: 2.3rem;
        text-decoration-line: underline;
        color: $purple;
        border: none;
        cursor: pointer;
        background-color: transparent;
      }
    }

    .delivery {
      margin-top: 2rem;

      .title {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 600;
        font-size: 1.7rem;
        line-height: 2.3rem;
        color: $black;
      }

      & > div {
        margin-top: 1rem;
        max-width: 23rem;
        font-size: 1.7rem;
        line-height: 2.3rem;
      }
    }
  }

  .continue {
    margin-top: 3.6rem;

    button {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 23px;
      text-align: center;
      text-decoration-line: underline;
      color: $purple;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.expectNext {
  padding: 4.8rem 0 6.4rem 0;

  .title {
    max-width: 40rem;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    font-family: Lulo Clean;
    font-style: normal;
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 2.5rem;
    text-align: center;
    color: $black;
  }

  .expectItems {
    margin-top: 4.5rem;
    display: flex;
    justify-content: space-between;

    .itemTitle {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      color: $black;
      max-width: 21.5rem;
      margin: auto;
    }

    .text {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      color: $black;
      max-width: 21.5rem;
      margin: auto;
    }

    .icon {
      text-align: center;
    }
  }
}

.button {
  font-size: 1.7rem;
  line-height: 1.35;
  color: $purple;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

@media (max-width: $table-screen) {
  .summaryWrapper {
    padding-top: 10rem;
  }

  .expectNext {
    padding: 3rem 0 3.5rem 0;

  
  
    .title {
      font-size: 1.5rem;
      line-height: 1.35;
    }

    .expectItems {
      margin-top: 2rem;
      display: flex;
      flex-wrap: wrap;

      & > div {
        width: 100%;

        + div {
          margin-top: 2rem;
        }
      }
      
      .itemTitle,
      .text {
        max-width: 50rem;
      }
    }

  }
}