@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/avenir-next/regular/avenir-next-regular.woff2') format('woff2'),
        url('../fonts/avenir-next/regular/avenir-next-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/avenir-next/medium/avenir-next-medium.woff2') format('woff2'),
        url('../fonts/avenir-next/medium/avenir-next-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/avenir-next/demi-bold/avenir-next-demi-bold.woff2') format('woff2'),
        url('../fonts/avenir-next/demi-bold/avenir-next-demi-bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lulo Clean';
    src: url('../fonts/lulo-clean/bold/lulo-clean-bold.woff2') format('woff2'),
        url('../fonts/lulo-clean/bold/lulo-clean-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

