@import "../../../../assets/styles/vars";

.shippingContainer {
  margin: 2.8rem 0 3.6rem 0;
}

.title {
  display: block;
  margin: 0 0 1rem;
  color: $black;
  font-size: 1.7rem;
  line-height: 1.4;
  font-weight: 600;
  font-family: $font-default;
}

@media (max-width: $table-screen) {
  .title {
    font-size: 1.5rem;
    line-height: 1.35;
  }
}