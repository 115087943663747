@import "assets/styles/normalize";
@import "assets/styles/fonts";
@import "assets/styles/vars";

*,
*::before,
*::after {
  box-sizing: border-box;
}

:active,
:focus,
:hover {
  outline: 0;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 10px;
}

body {
  font-size: 1.4rem;
  line-height: 1.28;
  min-width: 320px;
  font-family: $font-default;
  font-weight: 400;
  color: $text-black;
  background-color: $bg-white;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

b,
strong {
  font-weight: bolder;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  width: 100%;
  max-width: $container-default;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
}

#root {
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  flex-direction: column;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

// fade-transition

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 450ms ease-in;
}

.fade-exit {
  display: none;
}




.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: transform .5s ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: transform .5s ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: transform .5s ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: transform .5s ease;
}

.productNextButtonSwiper,
.productPrevButtonSwiper {
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 20%;
  display: block;
  z-index: 2;
  width: 1.7rem;
  height: 4.2rem;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: .6;
  cursor: pointer;
  transform: translateY(-50%);
  transition: opacity .3s;

  &:hover {
    opacity: 1;
  }
}

.productNextButtonSwiper {
  right: 3rem;
  background-image: url('/assets/images/icons/icon-arrow-right.svg');
}

.productPrevButtonSwiper {
  left: 3rem;
  background-image: url('/assets/images/icons/icon-arrow-left.svg');
}


.btn {

}

.btn-default {
  min-width: 32rem;
  background-color: $purple;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  color: $white;
  font-family: $font-default;
  font-weight: 600;
  font-size: 1.7rem;
  height: 4.4rem;
  padding: 1rem 3rem;
  transition: background-color .3s;
  backface-visibility: hidden;

  &:disabled {
    background: $gray-2;
    color: $white;
    cursor: default;
    &:hover {
      background: $gray-2;
    }
  }
  
}

@media (hover: hover) {
  .btn-default {
    &:hover {
      background-color: $purple-dark;
    }
  }
}

.btn-icon-check {
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 auto;
  display: block;
  background: url('/assets/images/icons/icon-added.svg') 50% 50% no-repeat;
  background-size: contain;
  opacity: 0;
  animation: fadeIn .5s forwards ease-in-out;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.btn-load {
  @extend .btn-default;

  .spinner {
    & .path {
      stroke: $white;
    }
  }
}

.btn-full {
  min-width: inherit;
  max-width: inherit;
  width: 100%;
}

.swiper-stretch {
  position: relative;

  &.pagination-relative {
    .swiper-pagination {
      position: static;
      margin: 1rem 0 0 0;
    }
  }

  &.no-pagination {
    .swiper-pagination {
      display: none;
    }
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-container {
    position: static;
  }

  .swiper-slide {
    height: auto;
    // width: 24.5rem;
    
    > div {
      height: 100%;
    }
  }

  .swiper-button-prev {
    left: -3.3rem;
  }

  .swiper-button-next {
    right: -3.3rem;
  }

  .swiper-pagination {
    bottom: -2.5rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    bottom: 0;
    display: block;
    z-index: 2;
    width: 1.7rem;
    height: 4.2rem;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .6;
    cursor: pointer;
    transform: translateY(-50%);
    transition: opacity .3s;

    &:hover {
      opacity: 1;
    }
  }

  .swiper-button-next {
    right: -3.4rem;
    background-image: url('/assets/images/icons/icon-arrow-right.svg');
  }

  .swiper-button-prev {
    left: -3.4rem;
    background-image: url('/assets/images/icons/icon-arrow-left.svg');
  }
}

.accordion-icon {
  width: 14px;
  height: 14px;
  display: block;
  position: relative;

  &:before,
  &:after {
    content:"";
    position: absolute;
    display: block;
    transition: opacity .3s;
    border-radius: .2rem;
  }

  &:before {
    top: 50%;
    width: 100%;
    left: 0;
    height: .2rem;
    background-color: $black;
    transform: translateY(-.1rem);
  }

  &:after {
    top: 0%;
    left: 50%;
    height: 100%;
    background-color: $black;
    width: .2rem;
    transform: translateX(-.1rem);
  }
}

.Mui-expanded {
  .accordion-icon {
    &:after {
      opacity: 0;
    }
  }
}


.slider-default {

  .swiper-container {
    width: 100%;
  }

  // .swiper-pagination,
  // .slider-button-next,
  // .slider-button-prev {
  //   display: none;
  // }

  .swiper-pagination {
    display: none;
  }

  .swiper-wrapper {
    display: flex;
  }

  &.center {
    .swiper-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

.MuiPickersBasePicker-pickerView {
  width: 100%;
}

.slider-book-type {
    .swiper-pagination {
      display: none;
    }

    .swiper-button-next {
      right: -2.4rem;
    }

    .swiper-button-prev {
      left: -2.4rem;
    }
}

.label-field {
  color: $black;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 1.4rem;
}

.swiper-button-prev,
.swiper-button-next {
  &.swiper-button-disabled {
    opacity: .08;
    cursor: default;
    pointer-events: none;
  }
}

@media (max-width: $table-screen) {
  .slider-default {
    .swiper-wrapper {
      justify-content: flex-start;
    }

    &.center {
      .swiper-wrapper {
        justify-content: flex-start;
      }
    }
  }

  .btn-default {
    width: 100%;
    min-width: inherit;
  }

  .swiper-stretch {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
}
@media (max-width: $mobile-screen) {
  
  .swiper-stretch {
    margin: 0 -2rem;

    .swiper-wrapper {
      .swiper-slide {

        &:first-child {
          padding-left: 2rem;
        }
        &:last-child {
          padding-right: 2rem;
        }
      }

    }
  }
}

.card-field {
  height: 40px;

  background: $white;
  border: 0.5px solid #c7c7c7;
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding: 1rem 1.5rem;
  width: 100%;
  margin: 1rem 0;
}

.spinner {
  animation: rotate 2s linear infinite;
  margin: 3rem auto;
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  
  & .path {
    stroke: $purple;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


