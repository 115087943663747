@import "../../../assets/styles/vars";

.summary {
  width: 32.5rem;
  position: relative;
  min-height: 27.1rem;
}

.description {
  padding-bottom: 2rem;
  border-bottom: .05rem solid #C7C7C7;
  font-size: 1.7rem;
  line-height: 2.3rem;
  margin-top: 2rem;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.total {
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.3rem;
  margin-top: 2rem;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  button {
    margin-top: 2rem;
  }
}

.consultationFee, 
.phlebotomistFee  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.phlebotomistFeeBox {
  padding: 2rem 0;
  border-top: 0.05rem solid #C7C7C7;
  border-bottom: 0.05rem solid #C7C7C7;
}

.posFixed {
  position: fixed;
  width: inherit;
  top: 6.5rem;
  margin-top: 15px;
}

.posAbsoluteBottom {
  position: absolute;
  bottom: 0;
  top: auto;
  width: inherit;
  margin-top: 15px;
}

.posAbsoluteTop {
  position: absolute;
  top: 0;
  width: inherit;
  margin-top: 15px;
}

.tooltip {
  font-size: 11px !important;
  line-height: 15px !important;
  font-family: Avenir Next;
}

.products {
  border-bottom: .1rem solid $gray-2;


  position: relative;

  &:before {
    content:"";
    height: 2rem;
    background: linear-gradient(0deg, #ffffff, rgba(255,255,255,0));
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  
}

.inner {
  max-height: 50vh;
  padding: 1rem 0 0 0;
  overflow-y: auto;
}

.product {
  display: flex;
  justify-content: space-between;
  &:last-child {
    padding-bottom: 2rem;
  }
  & > div:last-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-left: 1rem;
  }
  &:not(:first-child) {
    margin-top: 1.6rem;
  }
  &:not(:last-child) > div:last-child {
    border-bottom: .1rem solid $gray-2;
    padding-bottom: 2rem;
  }
}

.productImage {
  position: relative;
  width: 4.6rem;
  height: 4.6rem;
  & > img {
    width: 4.6rem;
    height: 4.6rem;
    border-radius: .4rem;
    object-fit: cover;
  }
}

.productName {
  font-size: 1.7rem;
  line-height: 2.3rem;
  max-width: 18.5rem;
}

.productPrice {
  margin-left: 2rem;
  font-size: 1.7rem;
  line-height: 2.3rem;
}

.counter {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  color: $white;
  background-color: $gray-1;
  display: flex;
  justify-content: center;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  top: -.4rem;
  right: 0;
  margin: 0 -.5rem -.5rem 0;
}

@media (max-width: $table-screen) {
  .summary {
    padding-top: 2rem;
    width: 100%;
    display: flex;
    // justify-content: flex-end;
    min-height: auto;
    border-top: .05rem solid $gray-2;
    h2 {
      display: none;
    }
    & > div {
      width: 100%;
      position: inherit;
    }
  }

  .total,
  .description {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .products {
    &:before {
      display: none;
    }
  }

  .inner {
    max-height: initial;
  }
}

@media (max-width: $mobile-screen) {
  .summary {
    & > div {
      width: 100%;
    }
  }
}