@import "../../assets/styles/vars";

.MuiTypography-body1 {
  font-family: Avenir Next !important;
  font-weight: normal !important;
  font-size: 1.7rem !important;
  line-height: 1.35 !important;
  text-transform: capitalize;
}

.MuiRadio-root {
  color: #c7c7c7 !important;
  padding: 9px !important;
  border-radius: 50% !important;
}

.Mui-checked {
  color: #480D94 !important;
}


@media (max-width: $table-screen) {
  .MuiTypography-body1 {
    font-size: 1.5rem !important;
  }
}