@import "../../assets/styles/vars";

.grid {
	display: flex;
	flex-direction: column;
}

.cell {
	width: 100%;
	+ .cell {
		margin-top: 2rem;
	}
}

.card {
	background-color: $gray-5;
	padding: 2rem 1.5rem;
	border-radius: 1rem;
}

.head {
	+ .body {
		margin-top: 1rem;
	}
}

.preview {
	height: 6.6rem;
	width: 6.6rem;
	border-radius: 50%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 1rem;
}

.name {
	display: block;
	font-size: 1.7rem;
	line-height: 1.35;
	font-weight: 600;
}

.excerpt {
	font-size: 1.5rem;
	line-height: 1.35;
	color: rgba($black, .6);
}

.description {
	font-size: 1.7rem;
	line-height: 1.35;

	p {
		margin: 0;

		+ p {
			margin-top: 1.5rem;
		}
	}
}


@media (max-width: $table-screen) {
	
}