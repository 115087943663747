@import "../../assets/styles/vars";

.other {
    margin: 5rem 0;
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.body {
    margin-top: 2.5rem;
}

.link {
    font-size: 1.7rem;
    line-height: 2.4rem;
    font-weight: 600;
    color: $purple;
}

.title {
    color: $black;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.6rem;
    display: block;
}

@media (max-width: $table-screen) {
    .title {
        font-size: 1.5rem;
    }

    .body {
        margin-top: 2rem;
    }

    .other {
        margin: 3rem 0;
    }

    .link {
        font-size: 1.5rem;
    }
}