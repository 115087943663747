@import "../../assets/styles/vars";

.select {
  width: 100%;
}

.label {
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.3rem;
}

.selectWrapper {
  margin-top: 1rem;
}

.placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.alert fieldset {
  border-color: red !important;
}

@media (max-width: $table-screen) {
  .label {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.35;
  }
}