@import "../../../assets/styles/vars";

.select {
  margin-top: 2.8rem;
  h3 {
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin: 0;
  }
}

.listDoctor {
  margin: 1rem -.5rem 0;

  .type {
    display: flex;
    align-items: center;
    padding: .2rem 2rem;
    // outline: .1rem solid $gray-2;
    box-shadow: 0 0 0 .1rem $gray-2;
    box-sizing: border-box;
    border-radius: 1rem;
    cursor: pointer;
    background: transparent;
    margin: .2rem 0;
    min-height: 6rem;
    width: 100%;
    transition: outline-color .3s;
    border: none;

    &:hover {
      box-shadow: 0 0 0 .1rem $gray-2;
      // outline: .1rem solid $gray-2;
    }

    &.active {
      box-shadow: 0 0 0 .2rem $purple;
      // outline: .2rem solid $purple;
    }
    & > img {
      margin-right: 1rem;
      width: 4rem;
      height: 4rem;
      flex: 0 0 4rem;
      border-radius: 50%;
      object-fit: cover;
    }
    & > span {
      font-size: 1.7rem;
      text-align: left;
      line-height: 2.3rem;
    }
  }
}

.cell {
  width: 33.333%;
  padding-left: .5rem;
  padding-right: .5rem;
  height: auto;
}

.listClinic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem -.5rem -1rem; 

  .type {
    padding: 1rem 2rem;
    // outline: .1rem solid $gray-2;
    box-shadow: 0 0 0 .1rem $gray-2;
    box-sizing: border-box;
    border-radius: 1rem;
    cursor: pointer;
    background: transparent;
    text-align: left;
    transition: box-shadow .3s;
    width: 100%;
    border: none;

    &:hover {
      //outline: .1rem solid $gray-2;
      box-shadow: 0 0 0 .1rem $gray-2;
    }

    &.active {
      box-shadow: 0 0 0 .2rem $purple;
      //outline: .2rem solid $purple;
    }
  }

  .clinic {
    width: 50%;
    padding: 0 .5rem 1rem;
  }
}

.clinicName {
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.3rem;
}

.clinicAddress {
  font-size: 1.5rem;
  line-height: 2rem;
}

.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: $table-screen) {

  .cell {
    width: 50%;
  }

  .select {
    margin-top: 2rem;
    h3 {
      font-size: 1.5rem;
      line-height: 1.35;
    }
  }
  
  .listDoctor {
  
    .type {
      padding: .2rem 1rem;
  
      & > span {
        font-size: 1.5rem;
        line-height: 1.35;
      }
    }
  }

  .clinicName {
    font-size: 1.5rem;
    line-height: 1.35;
  }
  
  .clinicAddress {
    font-size: 1.3rem;
    line-height: 1.35;
  }


  .listClinic {
    .type {
      padding: 1rem;
    }
  }

}

@media (max-width: $mobile-screen) {
  .listDoctor {

    .type {

      & > span {
        font-size: 1.5rem;
        line-height: 1.2;
      }
    }
    
  }
}