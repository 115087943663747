@import "../../assets/styles/vars";

.grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.cell {
	width: 100%;
	+ .cell {
		margin-top: 2rem;
	}
}

.card {
	border-radius: 1rem;
	padding: 2rem 1.5rem;
	color: $black;
	background: $gray-5;
}

.title {
	font-size: 1.7rem;
	line-height: 1.35;
	display: block;
	font-weight: 600;

	+ .symptoms,
	+ .description {
		margin-top: 1rem;
	}
}

.description {
	font-size: 1.7rem;
	line-height: 1.35;

	p {
		margin: 0;
	}
}

.symptoms {
	font-size: 1.7rem;
	line-height: 1.35;

	+ .description {
		margin-top: 1rem;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			padding-left: 2.5rem;

			&:before {
				content:"";
				width: 6px;
				height: 6px;
				background-color: $black;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 50%;
				margin: .8rem 0 0 .6rem;
			}

			+ li {
				margin-top: 1rem;
			}
		}
	}
}

@media (max-width: $table-screen) {
	.title,
	.symptoms,
	.description {
		font-size: 1.5rem;
	}
}