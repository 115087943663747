@import "../../assets/styles/vars";

.header {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $bg-white;
    border-bottom: 0.5px solid $gray-2;
}

.wrapper {
    position: relative;
    z-index: 2;
    background-color: $bg-white;
}

.logo {
    position: absolute;
    top: 0;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 9.2rem;
        height: 2.3rem;
        display: block; 
    }
}

.inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6.5rem;
    padding: 1rem 0;
    margin: 0 -5rem;
}

.list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.item {
    + .item {
        margin-left: 3.5rem;
    }
}

.panel {
    padding: 0 5rem;
}

@media (max-width: $table-screen) {
    .inner {
        margin: 0;
        height: 5rem;
    }

    .panel {
        padding: 0;
    }

    .logo {
    
        svg {
            width: 7rem;
            height: 2rem;
        }
    }
    .item {
        &.hide {
            display: none;
        }

        + .item {
            margin-left: 2rem;
        }
    }
}

@media (max-width: $mobile-screen) {
    
}