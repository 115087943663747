@import "../../../assets/styles/vars";

.address {
  .title {
    display: block;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.3rem;
  }
  input {
    width: 100%;
    background: $bg-white;
    border: 0.5px solid $gray-2;
    box-sizing: border-box;
    border-radius: .4rem;
    padding: 1rem 1.5rem;
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin-top: 1rem;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
    &:disabled {
      background: $bg-gray-2;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  & > div {
    display: flex;
    & > input:first-child {
      margin-right: 1rem;
    }
  }
}

@media (max-width: $table-screen) {
  .address {
    .title {
      font-size: 1.5rem;
      line-height: 1.35;
    }

    & > div {
      display: block;
      & > input:first-child {
        margin-right: 0;
      }
    }
  }
}