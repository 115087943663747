@import "../../assets/styles/vars";

.category {
    border-bottom: .1rem solid $gray-2;
}

.list {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 2rem 0;
    margin: 0;
}

.item {
    + .item {
        margin-left: 2rem;
    }
}

.button {
    cursor: pointer;
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;

    &:after {
        display:block;
        content: '';
        border-bottom: solid .1rem $black;  
        transform: scaleX(0);  
        transition: transform .3s;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
        }
    }

    &.active {
        font-weight: 600;
    }
}

@media (max-width: $table-screen) {
    .list {
        flex-wrap: wrap;
    }

    .item {
        margin: .5rem 1rem;
    }
}