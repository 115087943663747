@import "../../assets/styles/vars";

.help {
    display: block;
    width: 2.4rem;
    height: 2.4rem;

    svg {
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        fill: $black;
    }
}