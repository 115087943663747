@import "../../assets/styles/vars";

.cell {
	width: 20rem;

	+ .cell {
		padding-left: 1rem;
	}
}

.card {

}

.preview {
	height: 11rem;
	border-radius: 1rem;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;

	+ .title {
		margin-top: 1rem;
	}
}

.title {
	font-size: 1.7rem;
	line-height: 1.35;
	display: block;
	font-weight: 400;
}

@media (max-width: $table-screen) {
	.cell {
		width: 20rem;
	}

	.title {
		font-size: 1.5rem;
	}
}
