@import "../../assets/styles/vars";

.module {
  display: flex;
  margin: 0 -1rem;
}

.cell {
  padding: 0 1rem;
  flex: 1;
}

.counter {
  width: 12rem;
  border: 1px solid $gray-2;
  height: 4.4rem;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.quality {
  display: block;
  width: 4rem;
  overflow: hidden;
  text-align: center;
  font-size: 1.7rem;
  line-height: 1;
}

.decrement,
.increment {
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  height: 100%;
  width: 4rem;
  transition: opacity .3s;

  svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto;
  }

  &:hover {
    opacity: 1;
  }

  &[disabled]{
    opacity: .5;
  }
}
