@import "../../../../assets/styles/vars";


.info {

  margin-top: 2.8rem;
  padding-bottom: 2.8rem;
  border-bottom: 1px solid $gray-2;

  header {
    display: flex;
    justify-content: space-between;

    span {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 2.3rem;
      color: $text-black;
    }

    button {
      background: transparent;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 2rem;
      text-decoration-line: underline;
      color: #480D94;
      border: none;
      cursor: pointer;
    }
  }

  & > div {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 1.7rem;
    line-height: 2.3rem;
    color: $text-black;
    margin-top: 1rem;
  }
}

.label {
  margin-right: 1rem;
}

@media (max-width: $table-screen) {
  .info {

    margin-top: 2rem;
    padding-bottom: 2rem;
  
    header {
  
      span {
        font-size: 1.5rem;
        line-height: 1.35;
      }
    }
  
    & > div {
      font-size: 1.5rem;
      line-height: 1.35;
    }
  }
}