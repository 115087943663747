@import "../../assets/styles/vars";

.menu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.item {

    + .item {
        margin-top: 1rem;
    }
}

.link {
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    text-decoration: none;
    white-space: nowrap;
    transition: color .3s;

    &:hover {
        color: rgb(128,128,128);
    }
}

.headline {
    display: block;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    color: $white;
}