@import "../../assets/styles/vars";

.counter {
  width: 8rem;
  height: 2.8rem;
  border: .1rem solid #C7C7C7;
  box-sizing: border-box;
  border-radius: .4rem;
  // padding: .9rem .4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > button {
    outline: none;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
  }
  & > span {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (max-width: $table-screen) {
  .counter {
    & > span {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
}

@media (max-width: $mobile-screen) {
  
}