@import "../../../assets/styles/vars";

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 2.8rem;
  & > div {
    width: 31.6rem;
  }
  & > button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    border: none;
    background: transparent;
    transition: background-color .3s;
    span {
      display: block;
      margin-left: 0.7rem;
    }
  }
}

@media (max-width: $table-screen) {
  .buttonsContainer {
    > button {
      margin-right: 2.5rem;
    }

    & > div {
      width: 100%;
    }

  }
}