@import "../../assets/styles/vars";

.cell {
    width: 25.5rem;

    + .cell {
        padding-left: 1rem;
    }
}

.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $gray-5;
    border-radius: 1rem;
    padding: 2rem 1.5rem;
    font-size: 1.7rem;
    line-height: 1.35;
}

.title {
    display: block;
    font-weight: 600;
}

.header {
    padding-bottom: 2rem;

    + .body {
        padding-top: 2rem;
        border-top: .1rem solid $gray-2;
    }
}

.body {
    flex: 1;
}

.footer {
    margin-top: 2rem;
}


.list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.item {
    position: relative;
    padding-left: 2.5rem;

    + .item {
        margin-top: 1rem;
    }

    &:before {
        content:"";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 1.4rem;
        height: 1.4rem;
        background: url(../../assets/images/icons/check-default.svg) 50% 50% no-repeat;
		background-size: contain;
        margin: .4rem 0 0 0;
    }
}

@media (max-width: $mobile-screen) {
	.cell {
		width: 26.6rem;

        + .cell {
            padding-left: 0;
        }
    }
}