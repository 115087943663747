@import "../../../assets/styles/vars";

.MuiPickersCalendarHeader-daysHeader {
  display: none !important;
}

.MuiPickersBasePicker-pickerView {
  max-width: 100% !important;
  min-width: auto !important;
  justify-content: start !important;
}

.MuiPickersCalendarHeader-transitionContainer > .MuiTypography-body1,
.MuiAccordionSummary-content > .MuiTypography-body1 {
  font-family: Avenir Next !important;
  font-weight: 600 !important;
  font-size: 1.7rem !important;
  line-height: 2.3rem !important;
}

.MuiTypography-body2 {
  font-family: Avenir Next !important;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.MuiSvgIcon-root {
  width: 2rem !important;
  height: 2rem !important;
}

.MuiPickersStaticWrapper-staticWrapperRoot {
  background: transparent !important;
  max-width: 100% !important;
  min-width: 25.4rem !important;
}

div[role="presentation"] > button {
  height: 3.2rem !important;
  width: 3.2rem !important;
}

.MuiPickersDay-current {
  color: $purple !important;
}

.MuiPickersDay-daySelected {
  background-color: $purple !important;
  color: $text-white !important;
}

.MuiPickersDay-hidden {
  color: rgba(0, 0, 0, 0.38) !important;
  pointer-events: none !important;
  opacity: 1 !important;
}

.MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.38) !important;
  pointer-events: none !important;
  background-color: transparent !important;
}