@import "../../assets/styles/vars";

.summary {
  width: 32.5rem;
  position: relative;
  min-height: 27.1rem;
  h2 {
    font-family: Lulo Clean;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    margin: 0 0 2.8rem 0;
  }
}

.description {
  padding-bottom: 2rem;
  border-bottom: .05rem solid #C7C7C7;
  font-size: 1.7rem;
  line-height: 2.3rem;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.total {
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.3rem;
  margin-top: 2rem;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  button {
    margin-top: 2rem;
  }
}

.consultationFee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.posFixed {
  position: fixed;
  width: inherit;
  top: 6.5rem;
}

.posAbsoluteBottom {
  position: absolute;
  bottom: 0;
  top: auto;
  width: inherit;
}

.posAbsoluteTop {
  position: absolute;
  top: 0;
  width: inherit;
}

.tooltip {
  font-size: 11px !important;
  line-height: 15px !important;
  font-family: Avenir Next;
}

@media (max-width: $table-screen) {
  .summary {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    min-height: auto;
    h2 {
      display: none;
    }
    & > div {
      position: inherit;
      width: 32.5rem;
    }
  }

  .total,
  .description {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (max-width: $mobile-screen) {
  .summary {
    & > div {
      width: 100%;
    }
  }
}