@import "../../assets/styles/vars";

.grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.cell {
	width: 100%;
	+ .cell {
		margin-top: 2rem;
	}
}

.card {
	border-radius: 1rem;
	padding: 2rem 1.5rem;
	color: $black;
	background: $gray-5;
}

.summary {
	font-size: 1.7rem;
	line-height: 1.35;

	p {
		margin: 0;

		+ p {
			margin-top: 1rem;
		}
	}

	ul {
		padding: 0;
		margin: -1rem -1rem 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;

		li {
			width: 50%;
			padding: 1rem 1rem 0 3.5rem;
			position: relative;

			&:before {
				content:"";
				width: 2.4rem;
				height: 2.4rem;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				margin: .9rem 0 0 .5rem;
				background: url(../../assets/images/icons/check.svg) 50% 50% no-repeat;
				background-size: contain;
			}
		}
	}
}

@media (max-width: $table-screen) {
	.summary {
		font-size: 1.5rem;
	}
}

@media (max-width: $mobile-screen) {
	.summary {
		ul {
			li {
				width: 100%;
			}
		}
	}
}