@import "../../assets/styles/vars";

.breadcrumb {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-wrap: wrap;
}

.item {
    font-size: 1.5rem;
    line-height: 1.35;
    color: rgba(0,0,0,.2);
    position: relative;

    + .item {
        padding-left: 2rem;

        &:before {
            content:"/";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0 0 0 .6rem;
            color: $black;
        }
    }

    &.active {

    }
}

.link {
    color: $black;
    transition: color .3s;

    &:hover {
        color: rgba(0,0,0,.2);
    }
}