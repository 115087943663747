@import "../../assets/styles/vars";

.footer {
    background-color: $bg-gray-2;
    color: $white;
    padding-top: 3rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
}

.phone {
    white-space: nowrap;
}

.footerText {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.0rem;
    color: #000000;
    text-align: center;
    border-bottom: 1px solid $gray-2;
    padding-bottom: 1.8rem;

    span {
        font-weight: 600;
    }

    a {
        text-decoration: underline;
    }
}

.footerLinks {
    display: flex;
    justify-content: center;
    padding-top: 1.6rem;
    font-size: 1.5rem;
    line-height: 1.35;


    a {
        display: block;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        color: #000000;
        padding: 0 2rem;
        &:not(:last-child) {
            border-right: 0.5px solid $gray-2;
        }
    }
}

.copyright {
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-align: center;
    color: rgba($white,.4);
    margin-top: 3rem;

    p {
        margin: 0;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1ft 1fr 1fr;
    grid-template-areas: "one two three four";
    grid-gap: 2rem;
}

.cell {
    
    &.one {
        grid-area: one;
    }

    &.two {
        grid-area: two;
    }

    &.three {
        grid-area: three;
    }

    &.four {
        grid-area: four;
    }
}

.headline {
    display: block;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    color: $white;
}

.social {
    .headline {
        margin-bottom: 1rem;
    }
}

@media (max-width: $table-screen) {
  
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "one two three"
            "one two four";
    }

}

@media (max-width: $mobile-screen) {
  
    .grid {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "one two"
            "three four";
    }

    .footerText {
        font-size: 1.3rem;
    }

    .footerLinks {
        font-size: 1.3rem;

        a {
            padding: 0 1rem;
        }
    }

}