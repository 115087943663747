@import "../../assets/styles/vars";

.wrapper {
  background-color: $gray-5;
}

.banner {
  padding-bottom: 6rem;
  h2 {
    font-family: Lulo Clean;
    font-size: 2.4rem;
    padding: 8rem 0 4rem 0;
    margin: 0;
    text-align: center;
  }
}

.blocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    max-width: 70.4rem;
    display: flex;
    align-items: flex-start;
    margin-bottom: 3.8rem;
    & > img {
      width: 9.4rem;
      height: 9.4rem;
    }
    & > div {
      margin-left: 1rem;
      margin-top: .9rem;
      font-size: 1.7rem;
      line-height: 2rem;
      font-weight: 400; 
    }
  }
}

.title {
  font-family: Avenir Next;
  font-size: 1.7rem;
  line-height: 2rem;
  font-weight: 600;
}