@import "../../assets/styles/vars";

.grid {
	display: flex;
	flex-wrap: wrap;
}

.cell {
	width: 100%;

	+ .cell {
		margin-top: 2rem;
	}
}

.card {
	border-radius: 1rem;
	padding: 2rem 1.5rem;
	color: $black;
	background: $gray-5;
}

.description {
	font-size: 1.7rem;
	line-height: 1.35;

	ul {
		margin: 0;
    padding: 0;
    list-style: none;

		li {
			position: relative;
			padding-left: 2.5rem;

			&:before {
				content: "";
				width: 6px;
				height: 6px;
				background-color: #000;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 50%;
				margin: 0.8rem 0 0 0.6rem;
			}
		}
	}

	p {
		margin: 0;

		+ p {
			margin-top: 1rem;
		}
	}
}


@media (max-width: $table-screen) {
	.description {
		font-size: 1.5rem;
	}
}