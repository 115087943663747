@import "../../assets/styles/vars";

.inputWrapper {
  width: 100%;
  textarea {
    width: 100%;
    background: $bg-white;
    border: 0.5px solid $gray-2;
    box-sizing: border-box;
    border-radius: .4rem;
    padding: 1rem 1.5rem;
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin-top: 1rem;
    resize: none;
    min-height: 9rem;
    box-shadow: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
    &:disabled {
      background: $bg-gray-2;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .label {
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.3rem;
  }
}

@media (max-width: $table-screen) {
  .inputWrapper {
    .label {
      font-size: 1.5rem;
      line-height: 1.35;
    }
  }
}