@import "../../../../assets/styles/vars";

.wrapper {

  h1 {
    font-family: Lulo Clean;
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 2.5rem;
    max-width: 51rem;
    margin: 0;
  }
}

.consultationTypes {
  margin-top: 2.8rem;
  & > div {
    border: 0.5px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 1rem;
    padding: 2rem 2rem 2.4rem 2rem;
    & > div:first-child {
      padding-bottom: 2rem;
      border-bottom: 0.5px solid $gray-2;
    }
    ul {
      margin-top: 0.4rem;
      font-size: 1.7rem;
      line-height: 2.3rem;
      padding-left: 3rem;
      margin-bottom: 0;
    }
  }
  & > div:last-child {
    margin-top: 2rem;
  }
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.3rem;
  span {
    display: block;
    margin-left: .4rem;
  }
}

.description {
  margin-top: 0.4rem;
  font-size: 1.7rem;
  line-height: 2.3rem;
}

.lists {
  display: flex;
  justify-content: space-between;
}

.list {
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
    padding: 0;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 2.3rem;
}

.buttonContainer {
  width: 31.6rem;
}

.footerDescription {
  width: 25.7rem;
  font-size: 1.7rem;
  line-height: 2.3rem;
}

@media (max-width: $table-screen) {
  .wrapper {
    h1 {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.5;
    }
  }

  .consultationTypes {
    margin-top: 2rem;

    > div {
      ul {
        font-size: 1.5rem;
        line-height: 1.35;
      }
    }
  }

  .title,
  .description {
    font-size: 1.5rem;
    line-height: 1.35;
  }

  .footerDescription {
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.35;
    margin-bottom: 1.5rem;

    &:empty {
      display: none;
    }
  }

  .footer {
    flex-wrap: wrap;
  }
}