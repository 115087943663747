@import "../../../assets/styles/vars";

.wrapper {
  margin: 2rem 0 3.6rem 0;
}

.radioWrapper {
  padding: 1.8rem 1.5rem;
  outline: .1rem solid $gray-2;
  box-sizing: border-box;
  border-radius: 1rem;
  transition: outline-color .3s;

  &:hover {
    outline: .1rem solid $gray-2;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.active {
    outline: .2rem solid #480D94;
  }
}

.option {
  display: flex;
  align-items: center;
  & span {
    display: block;
    margin-left: 1.2rem;
  }
}

.plugin {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  span {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: $text-gray-2;
    width: 19.2rem;
  }
}

.disabledOption {
  opacity: 0.5;
}
