@import "../../assets/styles/vars";

.button {
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.3rem;
  color: $white;
  padding: 11px;
  text-align: center;
  background: $purple;
  border: none;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color .3s;
  &:disabled {
    background: $gray-2;
    color: $white;
    cursor: default;
  }
}

.alert {
	margin: 2.5rem 0;
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	padding: 0.75rem 3rem 1.25rem 0.75rem;
	position: relative;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}

.close {
	position: absolute;
	top: 0;
	right: 0;
	width: 3rem;
	height: 3rem;
	cursor: pointer;
	padding: .5rem;
	font-size: 2.5rem;
	line-height: 1;
	color: rgba(#000, .6);
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
}