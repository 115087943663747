@import "../../../assets/styles/vars";

.dateAndTime {
  border: .1rem solid $gray-2;
  box-sizing: border-box;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  width: 100%;

  .time {
    text-align: center;
  }
  .shortTime {
    width: 31.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.timeValue {
  padding: .6rem .3rem;
  //outline: .1rem solid $gray-2;
  box-shadow: 0 0 0 .1rem $gray-2;
  width: 100%;
  height: 3.2rem;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.5rem;
  line-height: 2rem;
  cursor: pointer;
  background-color: transparent;
  transition: box-shadow .3s;
  border: none;
  color: #000;
  &:hover {
    // outline: .1rem solid $gray-2;
    box-shadow: 0 0 0 .1rem $gray-2;
  }

  &.active {
    // outline: .2rem solid $purple !important;
    box-shadow: 0 0 0 .2rem $purple !important;
  }
  &:disabled {
    opacity: 0.7;
  }
}

.viewButton {
  color: $purple;
  margin-top: 2.4rem;
  text-decoration: underline;
  background: transparent;
  border: none;
  cursor: pointer;
}

.allTime {
  text-align: left;
  & > span:not(:last-child) {
    margin-bottom: 1.2rem;
    display: block;
  }
  & > span:not(:first-child) {
    margin-top: 2.1rem;
  }
  
  + .allTime {
    margin-top: 1rem;
  }
}

.listTime {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

  &.range {
    grid-template-columns: 1fr 1fr;
  }
}

.listTime,
.allTime {
  button:disabled {
    display: none;
  }
}

.cell {
  width: 50%;
  padding: 0 2rem;
}


.date {

  + .time {
    border-left: .1rem solid $gray-2;
  }
}

.disabled {
  opacity: 0.5;
}

@media (max-width: $table-screen) {
  .dateAndTime {
    flex-wrap: wrap;
  }

  .cell {
    width: 100%;
  }

  .date {

    + .time {
      border-left: none;
    }
  }
}