@import "../../assets/styles/vars";

.section {
  border-top: .1rem solid $gray-2;

  padding-top: 3rem;
}

.title {
  font-family: Lulo Clean;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  margin: 0 0 1rem 0;
}

.headline {
  text-align: center;
  @extend .title;
  text-transform: capitalize;
}

.links {
  margin-top: 1rem;
  a {
    margin-right: 1.2rem;
    &:hover svg g {
      fill: rgba(0, 0, 0, 0.7);
    }
  }
}

.singleQuestion {
  & > div:first-child {
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2.3rem;
    color: $text-black;
  }
  & > div:last-child {
    font-size: 1.7rem;
    line-height: 2.3rem;
    color: $text-black;
  }
  &:not(:last-child) {
    padding-bottom: 2.8rem;
    border-bottom: 0.5px solid $gray-2;
  }
  &:not(:first-child) {
    margin-top: 2.8rem;
  }
}

.content {

  p {
    margin: 0;

    + p {
      margin-top: 1.5rem;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul,
  ol {
    padding: 0;
    margin: 1.5rem 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 2.5rem;

      + li {
        margin-top: .5rem;
      }

      &:before {
        content: "";
        width: 6px;
        height: 6px;
        background-color: $text-gray;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        margin: 0.7rem 0 0 0.6rem;
      }
    }
  }
}

@media (max-width: $mobile-screen) {
  .title,
  .headline {
    text-align: left;
    font-size: 1.7rem;
  }

  .section {
    padding-top: 2rem;
  }
}