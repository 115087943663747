@import "../../assets/styles/vars";

.basket {
    position: relative;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
}

.icon {
    svg {
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        fill: $black;
        stroke: $black;
    }
}

.counter {
    min-width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    padding: 0 .3rem;
    color: $white;
    background-color: $purple;
    display: flex;
    justify-content: center;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 -.5rem -.5rem 0;
}

@media (max-width: $table-screen) {
    .icon {
        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    .basket {
        width: 1.8rem;
        height: 1.8rem;
    }
}