@import "../../../assets/styles/vars";

.notice {
  margin-top: 2.8rem;
  & > h4 {
    margin: 0;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  & > ul {
    padding-left: 3rem;
    margin: .4rem 0 0 0;
    li {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  & > div {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (max-width: $table-screen) {
  .notice {
    margin-top: 2rem;
    & > h4 {
      font-size: 1.3rem;
      line-height: 1.35;
    }
    & > ul {
      li {
        font-size: 1.3rem;
      line-height: 1.35;
      }
    }
    & > div {
      font-size: 1.3rem;
      line-height: 1.35;
    }
  }
}