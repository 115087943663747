@import "../../assets/styles/vars";


.breadcrumb {
  padding: 2rem 0 3rem;
}

.accordion {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 5rem;
}

.slide {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(649 / 1038) * 100%;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    display: block;
  }
}

.slider {
  position: relative;
  &:before {
    content:"";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1.5rem 0 0 -.1.5rem;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    border-top: .2rem solid $purple;
    border-right: .2rem solid $purple;
    border-bottom: .2rem solid $purple;
    border-left: .2rem solid $white;
    transform: translateZ(0);
    animation: load 1.1s infinite linear;

  }

  + .body {
    padding-top: 4rem;
  }
}

@-webkit-keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.body {
  padding: 4rem 0 5rem;
}

.product {
  display: flex;
  margin: 0 -1rem;
}

.aside {
  width: 37.5%;
  padding: 0 1rem;
}

.panel {
  width: 62.5%;
  padding: 0 1rem;
}

.description {
  font-size: 1.7rem;
  line-height: 1.35;

  p {
    margin: 0;
    
    + p {
      margin-top: 1.5rem;
    }
  }

  + .options {
    margin-top:3rem;
    border-top: 1px solid $gray-2;
    padding: 3rem 0 0 0;
  }
}

.title {
  display: block;
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: 600;
  margin: 0;
}

.price {
  margin-top: 1rem;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 1.35;
  color: rgba(0,0,0,.6);
}

.faq {
  margin: 5rem 0;
}

.highlight {

  + .highlight {
    margin-top: 2rem;
  }

  + p {
    margin: 0;
  }
}

.addToCart {

}

.doctors {
  margin-bottom: 3rem;
}

.warning {
  margin: 40px 0;
  font-size: 20px;
  color: $orange;
}


@media (max-width: $table-screen) {
  .slider {
    margin: 0 -2rem;
  }

  .body {
    margin: -2rem -2rem 0;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: $white;
    border-radius: 2rem 2rem 0 0;
    position: relative;
    z-index: 2;
  }

  .main-slider {
    .swiper-pagination {
      margin-bottom: 3rem;
    }

    .productNextButtonSwiper,
    .productPrevButtonSwiper {
      display: none;
    }
  }

}

@media (max-width: $mobile-screen) {
  .faq {
    margin: 2rem 0 5rem;
  }


  .product {
    display: flex;
    margin: 0 -1rem;
    flex-wrap: wrap;
  }

  .aside {
    width: 100%;
  }

  .panel {
    margin-top: 1rem;
    width: 100%;
  }

  .title {
    font-size: 2.4rem;
  }

  .price {
    font-size: 2rem;
  }

  .breadcrumb {
    display: none;
  }


}