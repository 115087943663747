@import "../../assets/styles/vars";

.grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
}

.cell {
    width: 25%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
}

.slide {
    width: 100%;
    .cell {
        width: 25%;
        margin-bottom: 0;
        padding-bottom: 2rem;
    }
}



.wrapper {
    margin: 3rem 0 5rem;
}

@media (max-width: $table-screen) {
    .wrapper {
        margin: 2rem 0 3rem;
    }
}

@media (max-width: $mobile-screen) {
    
    .slide {
        .cell {
            width: 50%;
        }
    }

    .cell {
        width: 50%;
    }
}