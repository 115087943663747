@import "../../../../assets/styles/vars";

.list {
	display: flex;
	margin: 0 -.5rem;

	li {
		width: 50%;
		padding: 0 .5rem;
	}
}