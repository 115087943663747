@import "../../assets/styles/vars";

.quiz {
    background-color: $white-1;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.panel {
    width: 100%;
    max-width: 45rem;
    margin-left: auto;
    margin-right: auto;
}


.title {
    font-size: 3.6rem;
    line-height: 1.35;
    font-family: $font-primary;
    display: block;
    text-align: center;
    color: $black;
    position: relative;
    z-index: 2;
}

.lines {
    position: relative;

    &:before,
    &:after {
        content: "";
        width: 18rem;
        height: 2rem;
        background-color: $orange;
        display: block;
        position: absolute;
        z-index: 1;
    }

    &:before {
        left: .5rem;
        top: 2.5rem;
    }

    &:after {
        left: 2.5rem;
        bottom: .2rem;
    }
}

.description {
    text-align: center;
    font-size: 1.7rem;

    p {
        margin: 0;
    }
}

.body {
    margin-top: 3rem;
}

.footer {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.button {
    background-color: $black;
    border-radius: 5rem;
    height: 3.6rem;
    color: $white;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20rem;
    font-size: 1.5rem;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 500;
}

@media (max-width: $mobile-screen) {
    .title {
        font-size: 2.4rem;
    }

    .lines {
        position: relative;
    
        &:before,
        &:after {
            content: "";
            width: 10rem;
            height: 1.2rem;
            background-color: $orange;
            display: block;
            position: absolute;
            z-index: 1;
        }
    
        &:before {
            left: calc(50% - 13.5rem);
            top: 1.6rem;
        }
    
        &:after {
            left: calc(50% - 12.5rem);
            bottom: .3rem;
        }
    }
}